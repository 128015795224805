<template>
  <v-card :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <Student v-if="isStudent" />
    <div v-else>
      <v-tabs
        v-model="tab"
        :background-color="darkmode ? '#1E1E1E' : 'grey lighten-5'"
        center-active
        @change="tabValue"
      >
        <v-tab>
          {{ $t("app.student") }}
        </v-tab>
        <v-tab v-if="isTeacher || isAdmin">
          {{ isAdmin ? $t("app.teacher") : $t("permission.my_permission") }}
        </v-tab>
        <v-tab v-if="isStaff || isAdmin">
          {{ isAdmin ? $t("app.staff") : $t("permission.my_permission") }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <StudentTab />
        </v-tab-item>
        <v-tab-item>
          <TeacherTab :init="$route.query.tab == 1" permissionType="TEACHER" />
        </v-tab-item>
        <v-tab-item>
          <TeacherTab :init="$route.query.tab == 2" permissionType="STAFF" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.permission"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    StudentTab: () => import("./Student"),
    TeacherTab: () => import("./Teacher"),
    Student: () => import("./student/")
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      tab: this.$route.query.tab | 0,
      isAdmin: this.$store.getters.g_role_type === "ADMIN",
      isStudent: this.$store.getters.g_role_type === "STUDENT",
      isTeacher: this.$store.getters.g_role_type === "TEACHER",
      isStaff: this.$store.getters.g_role_type === "STAFF"
    };
  },
  watch: {
    "$route.query.tab"(newVal) {
      if (this.tab !== newVal) this.tab = newVal;
    }
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "Permission", query: { tab: tab } });
    }
  }
};
</script>
